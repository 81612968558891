import React, { useRef, useState } from "react";
import DropdownListInterpret from "../Dropdown/DropdownInterpret";

const EntriesDropdown = ({ setSelectedEntries }) => {
  const [entriesDropdown, setEntriesDropdown] = useState(false);
  const entriesref = useRef(null);
  const [item, setItems] = useState(10);
  const entries = [
    {
      value: 10,
    },
    {
      value: 20,
    },
    {
      value: 30,
    },
    {
      value: 50,
    },
    {
      value: 100,
    },
  ];

  const handleEntriesDropdown = (value) => {
    setItems(value);
    setSelectedEntries(value);
    setEntriesDropdown(false);
  };

  return (
    <div className="flex flex-row mb-10 gap-3 ">
      <p className="my-auto text-[13px]" style={{ fontFamily: "Silka" }}>
        Entries
      </p>
      <div className="flex relative">
        <button
          className="border border-[#D3D5D8] rounded px-5 py-1.5 flex flex-row gap-3 items-center"
          onClick={() => {
            setEntriesDropdown(!entriesDropdown);
          }}
        >
          {item}
        </button>
        {entriesDropdown && (
          <div
            className="absolute z-40 border border-coalColor graphClass right-0 top-full text-xs h-auto overflow-scroll bg-white rounded-lg shadow-[0_3px_10px_rgb(0,0,0,0.2)] w-full mt-2"
            ref={entriesref}
          >
            {entries?.map((i) => (
              <DropdownListInterpret
                key={i?.value}
                title={i?.value}
                textsize="text-xs"
                onClick={() => handleEntriesDropdown(i?.value)}
              />
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default EntriesDropdown;
